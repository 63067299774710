import React from "react";
import { Link } from "gatsby";
import { AppState } from "store/reducers";
import { connect } from "react-redux";
import { EventReducerInterface } from "store/reducers/event.reducer";
import moment from "moment";

interface MoveToLiveWidgetProps {
    event: EventReducerInterface;
}

const MoveToLiveWidget: React.FC<MoveToLiveWidgetProps> = (props) => {
    const latestEvent = props.event.events
        .filter((event) => event.live_screen_enabled)
        .sort(
            (a, b) =>
                new Date(a.start_date).getTime() -
                new Date(b.start_date).getTime()
        )[0];

    if(!latestEvent) return (<span />);

    // Mukailtu koodia src\components\events\move-to-live-banner.component.tsx
    const timeDiff = moment.duration(moment(latestEvent.start_date).diff(new Date()));
    // Jos alkamisaikaan on enemmän kuin tunti, palautetaan false
    const linkIsActive = !(timeDiff.asHours() > 1 || moment(new Date()).isAfter(latestEvent.end_date));

    return (
        <div className="bg-white shadow rounded-md col-span-6 my-2 mx-2">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Siirry livesivulle
                </h3>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                        {linkIsActive ? 
                        <Link
                            to={`/live/${latestEvent.id}`}>
                            <div className="p-2 rounded-lg bg-primary-600 shadow-lg sm:p-3 mb-2 text-white hover:bg-primary-400">
                                <span className=" ">Siirry</span>
                            </div>
                        </Link> :
                         <p>Tällä hetkellä ei ole saatavilla aktiivista livesivua.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    event: state.event,
});

export default connect(mapStateToProps)(MoveToLiveWidget);
